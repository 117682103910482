var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mega-select", {
    staticClass: "mb-0",
    attrs: { options: _vm.groupsOptions },
    on: {
      select: function($event) {
        return _vm.$emit("select", _vm._value)
      }
    },
    model: {
      value: _vm._value,
      callback: function($$v) {
        _vm._value = $$v
      },
      expression: "_value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }