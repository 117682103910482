<template>
  <mega-select
    class="mb-0"
    v-model="_value"
    @select="$emit('select', _value)"
    :options="groupsOptions"
  />
</template>

<script>
export default {
  props: {
    routeGroup: {
      type: Number
    }
  },
  data() {
    return {
      loading: true,
      round_type: undefined,
      groups: []
    };
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    groupsOptions() {
      let arr = [
        { value: undefined, name: this.$t("all_models") },
        { value: 0, name: this.$t("wo_group") }
      ];

      this.groups.map(item => {
        arr.push({ value: item.idt_group, name: item.name });
      });

      return arr;
    },

    type_id() {
      let res = undefined;

      if (this.round_type === "3d") res = 4;
      if (this.round_type === "gif") res = 5;

      return res;
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.round_type = 4;

      let params = {
        limit: 500,
        idc_round_type: this.type_id,
        offset: 0,
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .get("/groups", { params })
        .then(response => {
          this.groups = [];

          this.groups = response.data.groups;
          this.loading = false;
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    }
  }
};
</script>
